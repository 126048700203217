// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"euqIkQBMKY0E1dvZpPhXE"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/login";import * as Sentry from '@sentry/nextjs'

const environment = process.env.NODE_ENV

if (environment === 'production') {
  Sentry.init({
    dsn: 'https://b3e2c35697d8fc296d6ecf6b2f8fcaab@o232275.ingest.sentry.io/4505875254018048',

    environment,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0, // Disable performance monitoring

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true
      })
    ],

    ignoreErrors: [
      'This Suspense boundary received an update before it finished hydrating.',
      'Minified React error #421'
    ]
  })
}
